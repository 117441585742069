<template>
  <div>
    <div style="background-color: #00308f" class="firstHeader">
      <v-container
        fluid
        class="pa-0 top-header"
        :class="{ fullwidth: $vuetify.breakpoint.mdAndDown }"
      >
        <v-app-bar
          height="40px"
          color="#00308F"
          dense
          dark
          flat
          class="appbar-content"
        >
          <template>
            <a
              :href="`${notice?.url}`"
              style="color: #fff"
              v-if="notice?.content"
            >
              <span :class="$vuetify.breakpoint.smAndDown ? 'ml-0' : 'ml-6'"
                ><span
                  class="d-inline-block"
                  :class="{ 'text-truncate': $vuetify.breakpoint.smAndDown }"
                  >{{ notice?.content }}</span
                >
                <v-icon class="ml-4">mdi-chevron-right</v-icon>
              </span>
            </a>
            <v-spacer></v-spacer>
            <span class="hidden-md-and-down">
              <v-icon>mdi-cloud-outline</v-icon>
              <span class="mr-4 ml-2">{{ temp }} °C {{ moderate_rain }}</span>
              <v-icon>mdi-clock</v-icon>
              <span class="mr-4 ml-2">IST {{ istTime }}</span>
              <!-- <AppButton :onClick="handleclick"></AppButton> -->
            </span>

            <!-- <v-select
              class="lang-Drop"
              label="Lang"
              :items="['En', 'Tamil', 'Telgue', 'Hindi']"
            ></v-select> -->

            <select
              v-model="language"
              :required="true"
              @change="changeLocation"
              class="lang-Drop"
            >
              <option value="AR">Arabic</option>
              <option value="ZH">Chinese (Mandarin)</option>
              <option value="EN">English</option>
              <option value="FR">French</option>
              <option value="HI">Hindi</option>
              <option value="RU">Russian</option>
              <option value="ES">Spanish</option>
              <option value="TA">Tamil</option>
            </select>
          </template>
        </v-app-bar>
      </v-container>
    </div>
    <v-container fluid class="top-header">
      <v-toolbar dense height="65px" flat class="secondHeader">
        <v-toolbar-title :class="{ 'logo-shape': $vuetify.breakpoint.mdAndUp }">
          <router-link to="/">
            <v-img
              :src="require('../assets/chennai-int-logo.png')"
              :class="{ 'logo-size': $vuetify.breakpoint.mdAndUp }"
              contain
              :width="$vuetify.breakpoint.smAndDown ? '90%' : '55%'"
            />
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <div class="hidden-md-and-down">
            <v-menu
              offset-y
              :nudge-width="1500"
              :open-on-hover="$vuetify.breakpoint.smAndDown ? false : true"
              v-for="(tMenu, index) in topMenu"
              :key="index"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="activate(1)"
                  :class="{ active: active_el == 1 }"
                >
                  {{ tMenu.title }}
                  <v-icon right dark> mdi-chevron-down </v-icon>
                </v-btn>
              </template>
              <v-card class="overflow-hidden top-menu">
                <v-row align="center" justify="center" class="">
                  <v-col class="text-center">
                    <h2>{{ tMenu.title }}</h2>
                  </v-col>
                  <v-col>
                    <v-list class="submenu-padding">
                      <v-list-item
                        v-for="(childMenu, cindex) of tMenu.children"
                        :key="cindex"
                      >
                        <v-list-item-title>
                          <!-- <router-link to="/flightinformation"> -->
                          <v-btn text @click="menuClick(childMenu)">
                            {{ childMenu.title }}
                          </v-btn>
                          <!-- </router-link> -->
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item>
                        <v-list-item-title>
                          <router-link to="/airlineinformation">
                            <v-btn text> Airline Information </v-btn>
                          </router-link>
                        </v-list-item-title>
                      </v-list-item> -->
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-img
                      :src="require('../assets/images/AIRINDIA.jpg')"
                      class="my-3"
                      contain
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </div>

          <v-btn
            icon
            to="/search"
            class="mt-2 topSearch"
            :class="{ topSearchMobile: $vuetify.breakpoint.smAndDown }"
          >
            <v-icon
              :width="$vuetify.breakpoint.smAndDown ? '50%' : '80%'"
              class="mr-1 mt-1"
              >mdi-magnify</v-icon
            >
            Search
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-title class="ml-15">
          <a href="https://www.aai.aero/" target="_blank">
            <v-img
              :src="require('../assets/logonew.png')"
              contain
              :width="$vuetify.breakpoint.smAndDown ? '94%' : '80%'"
          /></a>
        </v-toolbar-title>

        <!-- <v-btn color="#0072FF " icon small>
                    <v-img width="35" :src="require('../assets/images/avatar.svg')" />
                </v-btn> -->
        <v-app-bar-nav-icon
          @click.stop="drawer1 = !drawer1"
        ></v-app-bar-nav-icon>
      </v-toolbar>
    </v-container>
    <!-- class="right-menu -->
    <div class="">
      <!-- <v-navigation-drawer right v-model="drawer" fixed temporary :width="1500">
                <v-toolbar flat class="hidden-md-and-up">
                    <v-spacer></v-spacer>
                    <v-btn icon @click.stop="drawer = !drawer" style="position: relative; right: 8%; top: 4%; z-index: 1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-btn class="hidden-md-and-down" icon @click.stop="drawer = !drawer" style="position: absolute; right: 4%; top: 4%; z-index: 1">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="4" class="hidden-md-and-down">
                        <v-img :src="require('../assets/images/menu.jpg')" class="my-3" width="94%" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list nav dense class="right-drawer">
                            <v-list-item-group v-model="group" active-class="menu-select deep-purple--text text--accent-4">
                                <v-list-item>                                    
                                    <v-menu :offset-x="$vuetify.breakpoint.smAndDown?false:true" :offset-y="$vuetify.breakpoint.smAndDown?true:false" :open-on-hover="$vuetify.breakpoint.smAndDown?false:true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-title v-bind="attrs" v-on="on">Flights <v-icon right>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-title>
                                        </template>
                                        <v-list class="right-drawer-submenu">
                                            <router-link to="/flightinformation">
                                                <v-list-item>
                                                    <v-list-item-title>Flight Information</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                            <router-link to="/airlineinformation">
                                                <v-list-item>
                                                    <v-list-item-title>Airline Information</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                        </v-list>
                                    </v-menu>
                                </v-list-item>
                                <v-list-item>
                                    <v-menu :offset-x="$vuetify.breakpoint.smAndDown?false:true" :offset-y="$vuetify.breakpoint.smAndDown?true:false" :open-on-hover="$vuetify.breakpoint.smAndDown?false:true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-title v-bind="attrs" v-on="on">Before you Fly
                                                <v-icon right>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-title>
                                        </template>
                                        <v-list class="right-drawer-submenu">
                                            <v-list-item>
                                                <router-link to="/beforeyoufly">
                                                    <v-list-item-title>Arrival Guide</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <router-link to="/beforeyouflydeparture">
                                                <v-list-item>
                                                    <v-list-item-title>Departure Guide</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                            <router-link to="/beforeyouflytransitguide">
                                                <v-list-item>
                                                    <v-list-item-title>Transit Guide</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                            <router-link to="/covid19">
                                                <v-list-item>
                                                    <v-list-item-title>Covid-19</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                            <router-link to="/beforeyouflywaitingtime">
                                                <v-list-item>
                                                    <v-list-item-title>Waiting Time</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                        </v-list>
                                    </v-menu>
                                </v-list-item>
                                <v-list-item>
                                    <v-menu :offset-x="$vuetify.breakpoint.smAndDown?false:true" :offset-y="$vuetify.breakpoint.smAndDown?true:false" :open-on-hover="$vuetify.breakpoint.smAndDown?false:true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-title v-bind="attrs" v-on="on">To & From Airport
                                                <v-icon right>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-title>
                                        </template>
                                        <v-list class="right-drawer-submenu">
                                            <v-list-item>
                                                <router-link to="/mlcp">
                                                    <v-list-item-title>Parking</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/tofromairport">
                                                    <v-list-item-title>Metro Train</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/suburbantrain">
                                                    <v-list-item-title>Suburban Train</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/publicbus">
                                                    <v-list-item-title>Public bus</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/taxicabs">
                                                    <v-list-item-title>Taxis Cabs</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/carrentals">
                                                    <v-list-item-title>Car Rentals</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/pickupdropoff">
                                                    <v-list-item-title>Pickup & Drop off</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item>
                                <v-list-item>
                                    <v-menu :offset-x="$vuetify.breakpoint.smAndDown?false:true" :offset-y="$vuetify.breakpoint.smAndDown?true:false" :open-on-hover="$vuetify.breakpoint.smAndDown?false:true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-title v-bind="attrs" v-on="on">At The Airport
                                                <v-icon right>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-title>
                                        </template>
                                        <v-list class="right-drawer-submenu">
                                            <v-list-item>
                                                <router-link to="/DutyFree">
                                                    <v-list-item-title>Duty Free</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/foodanddrink">
                                                    <v-list-item-title>Food & Drink</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/relexandunwind">
                                                    <v-list-item-title>Relex & Unwind</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/shopping">
                                                    <v-list-item-title>Shopping</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item>
                                <v-list-item>
                                    <v-menu :offset-x="$vuetify.breakpoint.smAndDown?false:true" :offset-y="$vuetify.breakpoint.smAndDown?true:false" :open-on-hover="$vuetify.breakpoint.smAndDown?false:true">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-title v-bind="attrs" v-on="on">Services
                                                <v-icon right>
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-title>
                                        </template>
                                        <v-list class="right-drawer-submenu">
                                            <v-list-item>
                                                <router-link to="/baggages">
                                                    <v-list-item-title>Baggage</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/lostfound">
                                                    <v-list-item-title>Lost & Found</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/mlcp">
                                                    <v-list-item-title>Parking</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/specialassistance">
                                                    <v-list-item-title>Special Assistance</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/meetgreet">
                                                    <v-list-item-title>Meet & Greet</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/selfcheckin">
                                                    <v-list-item-title>Self Checkin</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/freewifi">
                                                    <v-list-item-title>Free Wifi</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/smokinglounge">
                                                    <v-list-item-title>Smoking Lounge</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/indiapost">
                                                    <v-list-item-title>India Post</v-list-item-title>
                                                </router-link>
                                            </v-list-item>
                                            <v-list-item>
                                                <router-link to="/prayerroom">
                                                    <v-list-item-title>Prayer Room</v-list-item-title>
                                                </router-link>
                                            </v-list-item>                                            
                                        </v-list>
                                    </v-menu>
                                </v-list-item>                             
                                <router-link to="/charmingChennai">
                                    <v-list-item>
                                        <v-list-item-title>Discover Chennai</v-list-item-title>
                                    </v-list-item>
                                </router-link>
                                <v-list-item>
                                    <router-link to="/contactus">
                                        <v-list-item-title>Contact Us</v-list-item-title>
                                    </router-link>
                                </v-list-item>                              
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-navigation-drawer> -->
      <!-- <rightMenu ></rightMenu> -->

      <v-navigation-drawer
        v-model="drawer1"
        temporary
        absolute
        right
        class="right-drawer"
        width="400"
        height="100vh"
        color="#00308F"
        dark
        style="position: fixed; bottom: 0; right: 0"
      >
        <v-toolbar flat class="" color="#00308F">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click.stop="drawer1 = !drawer1"
            style="position: relative; right: 4%; top: 4%; z-index: 1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list class="hamburgerMenu">
          <v-list-group v-for="navLink in navLinks" :key="navLink.text">
            <v-list-item slot="activator" @click="menuClick(navLink)">
              <v-list-item-content>
                <v-list-item-title>{{ navLink.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="sub in navLink.children"
              :key="sub.title"
              @click="menuClick(sub)"
            >
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <!-- <serevicetemplate v-if="false" :content="contentID" /> -->
  </div>
</template>
<script>
//import AppButton from '../components/Button'
//import rightMenu from '../components/rightMenu'
import axios from "axios";
const APP_ID = process.env.VUE_APP_APP_ID;
// import serevicetemplate from "@/views/templates/services.vue";
import EventBus from "../event-bus";
import { createRouter } from "vue-router";
const _ = require("lodash");

export default {
  name: "topHeader",

  components: {
    //AppButton,
    // rightMenu,
    // serevicetemplate,
  },

  showMenu: false,
  data: () => ({
    active_el: 0,
    drawer: false,
    drawer1: false,
    group: null,
    model: "Leider",
    navLinks: [
      //    {
      //         text: "About Us",
      //         link: "/aboutus",

      //     },

      {
        text: "About Us",
        link: "",
        subLinks: [
          { text: "About Chennai Airport", link: "/about-chennai-airport" },
          { text: "Why Chennai Airport", link: "/about-us" },
          { text: "Vision and Mission", link: "/visionandmission" },
          { text: "Our Partners / Advertise", link: "/advertisement" },
        ],
      },

      {
        text: "Flights",
        link: "",
        subLinks: [
          { text: "Flight Information", link: "/flightinformation" },
          { text: "Airline Information", link: "/Airline_Information" },
        ],
      },
      {
        text: "Before you Fly",
        link: "",
        subLinks: [
          { text: "Arrival Guide", link: "/beforeyoufly" },
          { text: "Departure Guide", link: "/beforeyouflydeparture" },
          { text: "Transit Guide", link: "/beforeyouflytransitguide" },
          { text: "Travel Check List", link: "/travelchecklist" },
          { text: "Baggage Rules", link: "/baggagerules" },
          { text: "Things to Do", link: "/beforeyouflywaitingtime" },
          { text: "Covid - 19 Guidelines", link: "/covid19" },
        ],
      },
      {
        text: "From & To the Airport",
        link: "",
        subLinks: [
          { text: "Parking", link: "/mlcp" },
          { text: "Multimodal Connectivity", link: "/multimodalConnectivity" },
          { text: "Metro Train", link: "/tofromairport" },
          { text: "Suburban Train", link: "/suburbantrain" },
          { text: "Public Buses", link: "/publicbus" },
          { text: "Taxis / Cabs", link: "/taxicabs" },
          { text: "Car Rentals", link: "/carrentals" },
          { text: "Pickup & Drop off", link: "/pickupdropoff" },
        ],
      },
      {
        text: "At The Airport",
        link: "",
        subLinks: [
          { text: "Duty Free", link: "/DutyFree" },
          { text: "Food & Beverages", link: "/foodanddrink" },
          { text: "Relex & Unwind", link: "/relexandunwind" },
          { text: "Shopping", link: "/shopping" },
          {
            text: "Domestic/International Transfer Facilities",
            link: "/domestictodomestic",
          },
          { text: "Entry Gates", link: "/entrygates" },
          { text: "Exit Gates", link: "/exitgates" },
        ],
      },
      {
        text: "Services",
        link: "",
        subLinks: [
          { text: "Accessibility", link: "/accessibility" },
          { text: "Airline Ticket Counters", link: "/airlineticketcounters" },

          { text: "Baggage Wrapping", link: "/baggages" },
          { text: "Check-in Counters", link: "/checkinCounters" },
          { text: "Child Care", link: "/childcare" },
          { text: "Free Wifi", link: "/freewifi" },
          { text: "India Post", link: "/indiapost" },
          { text: "Inter Terminal Transfer", link: "/interterminaltransfer" },
          { text: "Lost & Found", link: "/lostfound" },
          { text: "Medical Facilities", link: "/medicalfacilities" },
          { text: "Meet & Greet", link: "/meetgreet" },
          { text: "Money Exchange", link: "/moneyexchange" },
          { text: "Pharmacy", link: "/pharmacies" },
          { text: "Self Check-in", link: "/selfcheckin" },
          { text: "Sleeping Pods", link: "/sleepinglounge" },
          { text: "Smoking Lounge", link: "/smokinglounge" },
          { text: "Special Assistance", link: "/specialassistance" },
          {
            text: "Virtual Baggage Identification System (VBIS)",
            link: "/virtualbaggage",
          },
          { text: "Walkalator", link: "/walkalator" },

          // { text: "Parking", link: "/mlcp" },
          // { text: "Prayer Room", link: "/prayerroom" },
          // { text: "Atms", link: "/atmsServices" },
        ],
      },

      { text: "Avsar", link: "/avsar" },

      { text: "Art & Artefacts", link: "/artndartefacts" },

      { text: "Discover Chennai", link: "/charmingChennai" },

      { text: "FAQ's", link: "/faqs" },

      { text: "Contact Us", link: "/contactus" },
    ],

    offset: true,
    istTime: "",
    temp: "",
    moderate_rain: "",
    selectedLang: "EN",
    topMenu: [],
    menuList: [],
    notice: {
      content: "",
      url: "",
    },
  }),
  created() {
    EventBus.$on("noticeChange", (data) => {
      this.notice = {
        content: data.content,
        url: data.url,
      };
    });
  },
  computed: {
    // language() {
    //   this.selectedLang = this.$store.getters.getLanguage;
    //   return this.$store.getters.getLanguage;
    // },
    language: {
      get() {
        return this.$store.getters.getLanguage;
      },
      set(lang) {
        this.$store.commit("setLanguage", lang);
      },
    },
    contentID: {
      get() {
        return (
          this.$store.getters.getContentID || localStorage.getItem("content_id")
        );
      },
      set(id) {},
    },
  },
  // emits: ["toggle-favorite"],
  methods: {
    activate: function (el) {
      this.active_el = el;
    },
    handleclick() {},
    DisplayCurrentTime() {
      let date = new Date();
      let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let am_pm = date.getHours() >= 12 ? "PM" : "AM";
      hours = hours < 10 ? "0" + hours : hours;
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      this.istTime = hours + ":" + minutes + " " + am_pm;
      axios
        .post(
          `https://api.openweathermap.org/data/2.5/weather?q=chennai&APPID=${process.env.VUE_APP_APP_ID}`
        )
        .then((tempData) => {
          this.temp = tempData.data.main.temp - 273.15;
          this.temp = parseFloat(this.temp).toFixed(1);
          this.moderate_rain = tempData.data.weather[0]["description"];
        });
    },

    async getMenu() {
      this.navLinks = [];
      this.topMenu = [];
      let menus = await axios.post(
        `${process.env.VUE_APP_AIRLlINE_URL}/user/facility`,
        {
          page: 1,
          limit: 100,

          filters: {
            main: true,
            language: this.language,
          },
        }
      );

      let menuList = menus.data.data.docs;
      menuList = menuList.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      menuList.forEach((element) => {
        if (element.name != "") {
          this.navLinks.push(element);
          if (element.header) this.topMenu.push(element);
        }
      });
      return _.flatMapDeep(menuList, this.getMembers);
    },
    changeLocation() {
      this.getMenu();
    },
    async menuClick(menu) {
      this.$store.commit("setContentID", menu._id);
      if (menu.children != undefined) if (menu.children.length > 0) return;

      this.contentID = menu._id;

      let content = await axios.post(
        `${process.env.VUE_APP_AIRLlINE_URL}/user/facility/detail`,
        { _id: menu._id }
      );
      menu = { ...content.data.data, _id: menu._id };
      if (
        menu.cms ||
        menu.cms == undefined ||
        menu.template === 2 ||
        menu.template === 3
      ) {
        this.$router.addRoute({
          path: `/${menu.name.replace(/ /g, "_")}`,
          name: menu.name,
          component: () => import("../views/templates/maintemplate.vue"),
        });
        await this.$router.push(`/${menu.name.replace(/ /g, "_")}`);
        EventBus.$emit("contentChange", menu);
      } else {
        this.$router.push(menu.url).catch((err) => {
          EventBus.$emit("contentChange", menu._id);
        });
      }
    },
    getMembers(member) {
      if (!member.children || !member.children.length) {
        return member;
      }
      return [member, _.flatMapDeep(member.children, this.getMembers)];
    },
  },
  async mounted() {
    // localStorage.setItem('content_id',"")
    this.DisplayCurrentTime();
    let menuList = await this.getMenu();

    setInterval(() => {
      this.DisplayCurrentTime();
    }, 60 * 1000);
    let refreshMenu = menuList.find((item) => {
      return item._id == localStorage.getItem("content_id");
    });
    if (refreshMenu) this.menuClick(refreshMenu);
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appbar-content {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.active {
  color: #1b63f8;
  font-weight: bold;
}

/* .active3 {
  color: blue;
  font-weight: bold;
} */

.v-btn:not(.v-btn--round).v-size--default {
  height: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}

.fullwidth {
  width: 100% !important;
}

.top-header {
  width: 100%;
}

/*.menu-select{background-color: red}
.menu-select .v-list-item-title{background-color: green}*/
.top-menu .v-btn.v-size--default {
  font-size: 18px !important;
  font-weight: 400;
  padding: 10px;
}

.top-menu .v-menu__content.theme--light.menuable__content__active {
  width: 100% !important;
  left: 0;
  max-width: 100% !important;
}

.top-menu > .v-menu__content {
  z-index: 0 !important;
}

.v-menu__content {
  box-shadow: none;
}

.top-menu-drop .v-menu__content {
  z-index: 0;
}

.right-menu .v-list-item .v-list-item__title {
  padding: 21px 0;
  font-family: "Satoshi-Bold";
  font-size: 18px;
}

.right-drawer .v-list-item {
  margin-bottom: 0;
}

.right-drawer-submenu .v-list-item {
  margin-bottom: 0px;
  min-height: 30px !important;
}

.right-drawer .v-list-item .v-menu .v-list-item {
  border: 1px solid red;
}

.v-list-item--link::before {
  background-color: none !important;
}

.firstHeader {
  position: relative;
  z-index: 2;
}

.secondHeader {
  z-index: 1;
}

.secondHeader .v-btn:not(.v-btn--round).v-size--default {
  font-family: "Satoshi-Bold";
  font-size: 18px;
}

.text-truncate {
  vertical-align: middle;
  font-size: 7px;
}

.logo-shape {
  z-index: 9;
}
.logo-shape:before {
  position: absolute;
  content: "";
  background-color: #fff;
  box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.2);
  display: block;
  width: 600px;
  border-radius: 125px;
  top: -548px;
  height: 600px;
  left: -204px;
  transform: rotate(45deg);
}

.v-menu__content {
  box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.2) !important;
}

.logo-size {
  margin-left: 45px;
  margin-top: 22px !important;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.topSearch {
  text-transform: capitalize;
  width: auto !important;
  height: 50px !important;
  padding: 0 10px;
  font-size: 18px;
  color: #374151;
}
.topSearch:focus,
.topSearch:hover {
  background-color: none !important;
}

.topSearchMobile {
  width: 50px !important;
  height: inherit !important;
  font-size: 14px;
  margin-left: 20px;
}

.lang-Drop {
  max-width: 8% !important;
  font-weight: normal;
  color: #fff;
  border: 0;
}

.lang-Drop option {
  color: #1b63f8;
}

/* .v-toolbar__content .v-btn.v-btn--icon.v-size--default, .v-toolbar__extension .v-btn.v-btn--icon.v-size--default { width: 90px;} */
</style>
