import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeViewHindi from "../views/HomeViewHindi.vue";
import HomeViewTamil from "../views/HomeViewTamil.vue";
import HomePageTemplate from "../views/templates/HomePageTemplate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/templates/HomePageTemplate.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  // {
  //     path: '/avsar',
  //     name: 'avsar',
  //     component: () =>
  //         import ( /* webpackChunkName: "about" */ '../views/avsar.vue')
  // },

  {
    path: "/feedback",
    name: "feedback",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/feedBack.vue"),
  },

  {
    path: "/lostfound",
    name: "lostfound",
    component: () => import("../views/services/LostFound.vue"),
  },
  // {
  //     path: '/flight-information',
  //     name: 'flightinformation',
  //     component: () =>
  //         import ('../views/templates/FlightInformationTemplate.vue')
  // },
  {
    path: "/demo",
    name: "demo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/demo.vue"),
  },

  {
    path: "/details/:Pid",
    name: "details",
    //component: details
    component: () => import("../views/details.vue"),
  },
  {
    path: "/trackflights/",
    name: "trackFlights",
    component: () => import("../views/flights/trackflights.vue"),
  },
  {
    path: "/DutyFree",
    name: "dutyfree",
    component: () => import("../views/attheairport/DutyFree.vue"),
  },

  {
    path: "/dutyfreeviewall",
    name: "dutyfreeviewall",
    component: () => import("../views/attheairport/DutyFreeViewAll.vue"),
  },

  {
    path: "/FoodandDrink",
    name: "foodanddrink",
    component: () => import("../views/attheairport/FoodandDrink.vue"),
  },
  {
    path: "/RelexandUnwind",
    name: "relexandunwind",
    component: () => import("../views/attheairport/RelexandUnwind.vue"),
  },
  {
    path: "/shopping-old",
    name: "shoppingAt",
    component: () => import("../views/attheairport/shopping.vue"),
  },
  {
    path: "/charmingChennai",
    name: "charmingChennai",
    component: () => import("../views/charmingChennai/charmingChennai.vue"),
  },

  {
    path: "/adventures",
    name: "adventures",
    component: () => import("../views/charmingChennai/adventures.vue"),
  },

  {
    path: "/surfing",
    name: "surfing",
    component: () => import("../views/charmingChennai/surfing.vue"),
  },
  {
    path: "/hiking",
    name: "hiking",
    component: () => import("../views/charmingChennai/Hiking.vue"),
  },

  {
    path: "/camping",
    name: "camping",
    component: () => import("../views/charmingChennai/camping.vue"),
  },

  {
    path: "/trekking",
    name: "trekking",
    component: () => import("../views/charmingChennai/trekking.vue"),
  },

  {
    path: "/parasailing",
    name: "parasailing",
    component: () => import("../views/charmingChennai/ParaSailing.vue"),
  },

  {
    path: "/festivalandevents",
    name: "festivalandevents",
    component: () => import("../views/charmingChennai/FestivalAndEvents.vue"),
  },

  {
    path: "/heritage",
    name: "heritage",
    component: () => import("../views/charmingChennai/Heritage.vue"),
  },

  {
    path: "/educationaltourism",
    name: "educationaltourism",
    component: () => import("../views/charmingChennai/educationalTourism.vue"),
  },
  {
    path: "/natyanjalidancefestival",
    name: "natyanjalidancefestival",
    component: () =>
      import("../views/charmingChennai/NatyanjaliDanceFestival.vue"),
  },
  {
    path: "/musicdancefestivalchennai",
    name: "musicdancefestivalchennai",
    component: () =>
      import("../views/charmingChennai/MusicDanceFestivalChennai.vue"),
  },

  {
    path: "/theindiandancefestival",
    name: "theindiandancefestival",
    component: () =>
      import("../views/charmingChennai/TheIndianDanceFestival.vue"),
  },

  {
    path: "/harvestfestival",
    name: "harvestfestival",
    component: () => import("../views/charmingChennai/HarvestFestival.vue"),
  },

  {
    path: "/jallikattubullfestival",
    name: "jallikattubullfestival",
    component: () =>
      import("../views/charmingChennai/JallikattuBullFestival.vue"),
  },

  {
    path: "/chennaivizha",
    name: "chennaivizha",
    component: () => import("../views/charmingChennai/ChennaiVizha.vue"),
  },
  {
    path: "/karthigaldeepan",
    name: "karthigaldeepan",
    component: () => import("../views/charmingChennai/karthigalDeepan.vue"),
  },

  {
    path: "/pongalfestival",
    name: "pongalfestival",
    component: () => import("../views/charmingChennai/pongalFestival.vue"),
  },

  {
    path: "/chithiraifestival",
    name: "chithiraifestival",
    component: () => import("../views/charmingChennai/ChithiraiFestival.vue"),
  },

  {
    path: "/indiatourismandtradefair",
    name: "indiatourismandtradefair",
    component: () =>
      import("../views/charmingChennai/IndiaTourismandTradeFair.vue"),
  },
  {
    path: "/mamallapuramshoretemple",
    name: "mamallapuramshoretemple",
    component: () =>
      import("../views/charmingChennai/MamallapuramShoreTemple.vue"),
  },
  {
    path: "/nilgirimountainrailway",
    name: "nilgirimountainrailway",
    component: () =>
      import("../views/charmingChennai/NilgiriMountainRailway.vue"),
  },
  {
    path: "/gangaikondacholapuram",
    name: "gangaikondacholapuram",
    component: () =>
      import("../views/charmingChennai/GangaikondaCholapuram.vue"),
  },
  {
    path: "/crocodilebank",
    name: "crocodilebank",
    component: () => import("../views/charmingChennai/CrocodileBank.vue"),
  },

  {
    path: "/touristdestination",
    name: "touristdestination",
    component: () => import("../views/charmingChennai/touristDestination.vue"),
  },

  {
    path: "/kanchipuram",
    name: "kanchipuram",
    component: () => import("../views/charmingChennai/kanchipuram.vue"),
  },
  {
    path: "/marinabeach",
    name: "marinabeach",
    component: () => import("../views/charmingChennai/marinaBeach.vue"),
  },
  {
    path: "/thiruvanmiyurbeach",
    name: "thiruvanmiyurbeach",
    component: () => import("../views/charmingChennai/thiruvanmiyurBeach.vue"),
  },
  {
    path: "/mahabalipuram",
    name: "mahabalipuram",
    component: () => import("../views/charmingChennai/mahabalipuram.vue"),
  },
  {
    path: "/kodaikanal",
    name: "kodaikanal",
    component: () => import("../views/charmingChennai/kodaikanal.vue"),
  },
  {
    path: "/kanyakumari",
    name: "kanyakumari",
    component: () => import("../views/charmingChennai/kanyakumari.vue"),
  },

  {
    path: "/ooty",
    name: "ooty",
    component: () => import("../views/charmingChennai/ooty.vue"),
  },

  {
    path: "/madurai",
    name: "madurai",
    component: () => import("../views/charmingChennai/madurai.vue"),
  },

  {
    path: "/arignarannazoologicalpark",
    name: "arignarannazoologicalpark",
    component: () =>
      import("../views/charmingChennai/arignarannazoologicalPark.vue"),
  },

  {
    path: "/guindynationalpark",
    name: "guindynationalpark",
    component: () => import("../views/charmingChennai/guindynationalPark.vue"),
  },

  {
    path: "/governmentmuseums",
    name: "governmentmuseums",
    component: () => import("../views/charmingChennai/governmentMuseums.vue"),
  },

  {
    path: "/railmuseum",
    name: "railmuseum",
    component: () => import("../views/charmingChennai/railMuseum.vue"),
  },
  {
    path: "/spiritualtourism",
    name: "spiritualtourism",
    component: () => import("../views/charmingChennai/spiritualTourism.vue"),
  },
  {
    path: "/meenakshiamman",
    name: "meenakshiamman",
    component: () =>
      import("../views/charmingChennai/MeenakshiAmmanTemple.vue"),
  },
  {
    path: "/kapaleeshwarar",
    name: "kapaleeshwarar",
    component: () =>
      import("../views/charmingChennai/KapaleeshwararTemple.vue"),
  },
  {
    path: "/sriparthasarathy",
    name: "sriparthasarathy",
    component: () =>
      import("../views/charmingChennai/SriParthasarathyTemple.vue"),
  },
  {
    path: "/arulmigudhandayuthapaniswamy",
    name: "arulmigudhandayuthapaniswamy",
    component: () =>
      import("../views/charmingChennai/ArulmiguDhandayuthapaniSwamyTemple.vue"),
  },
  {
    path: "/tirumalatirupathi",
    name: "tirumalatirupathi",
    component: () =>
      import("../views/charmingChennai/TirumalaTirupathiDevasthanam.vue"),
  },
  {
    path: "/santhomecathedralbasilica",
    name: "santhomecathedralbasilica",
    component: () =>
      import("../views/charmingChennai/SanthomeCathedralBasilica.vue"),
  },
  {
    path: "/theholycrosschurch",
    name: "theholycrosschurch",
    component: () => import("../views/charmingChennai/TheHolyCrossChurch.vue"),
  },
  {
    path: "/thousandlightsmosque",
    name: "thousandlightsmosque",
    component: () =>
      import("../views/charmingChennai/ThousandLightsMosque.vue"),
  },

  // {
  //     path: '/services',
  //     name: 'airportServices',
  //     component: () =>
  //         import ('../views/services/airportServices.vue')
  // },
  {
    path: "/tofromairport",
    name: "tofromairport",
    component: () => import("../views/toFrom/toFromAirport.vue"),
  },
  {
    path: "/beforeyoufly",
    name: "beforeyoufly",
    component: () => import("../views/beforeyoufly/beforeYoufly.vue"),
  },
  {
    path: "/beforeyouflydeparture",
    name: "beforeyouflydeparture",
    component: () => import("../views/beforeyoufly/beforeYouflyDeparture.vue"),
  },
  {
    path: "/beforeyouflytransitguide",
    name: "beforeYouflyTransitGuide",
    component: () =>
      import("../views/beforeyoufly/beforeYouflyTransitGuide.vue"),
  },
  {
    path: "/beforeyouflywaitingtime",
    name: "beforeYouflywaitingtime",
    component: () =>
      import("../views/beforeyoufly/beforeYouflyWaitingTime.vue"),
  },

  {
    path: "/baggagerules",
    name: "baggagerules",
    component: () => import("../views/beforeyoufly/baggageRules.vue"),
  },

  {
    path: "/underconstruction",
    name: "underconstruction",
    component: () => import("../views/underConstruction.vue"),
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/contactus",
    name: "contactUs",
    component: () => import("../views/contactUs.vue"),
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () => import("../views/privacyPolicy.vue"),
  },
  {
    path: "/parking",
    name: "MutiLevelCarParking",
    component: () =>
      import("../views/templates/MutiLevelCarParkingTemplate.vue"),
  },

  {
    path: "/mlcpcharges",
    name: "MutiLevelCarParkingCharges",
    component: () => import("../views/services/MutiLevelCarParkingCharges.vue"),
  },

  {
    path: "/covid19",
    name: "covid19Safety",
    component: () => import("../views/beforeyoufly/covid19.vue"),
  },

  {
    path: "/atairport",
    name: "atairport",
    component: () => import("../views/attheairport/atAirport.vue"),
  },
  {
    path: "/attheairport",
    name: "attheairport",
    component: () => import("../views/attheairport/atTheAirport.vue"),
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () => import("../views/disclaimerChennaiAirport.vue"),
  },

  {
    path: "/baggages",
    name: "baggageServices",
    component: () => import("../views/services/baggageServices.vue"),
  },
  {
    path: "/virtualbaggage",
    name: "virtualbaggage",
    component: () => import("../views/services/virtualBaggage.vue"),
  },
  {
    path: "/specialassistance",
    name: "specialAssistance",
    component: () => import("../views/services/specialAssistance.vue"),
  },
  {
    path: "/meetgreet",
    name: "meetGreet",
    component: () => import("../views/services/meetGreet.vue"),
  },
  {
    path: "/selfcheckin",
    name: "selfCheckin",
    component: () => import("../views/services/selfCheckin.vue"),
  },
  {
    path: "/freewifi",
    name: "freeWifi",
    component: () => import("../views/services/freeWifi.vue"),
  },
  {
    path: "/smokinglounge",
    name: "smokingLounge",
    component: () => import("../views/services/smokingLounge.vue"),
  },
  {
    path: "/indiapost",
    name: "indiaPost",
    component: () => import("../views/services/indiaPost.vue"),
  },
  {
    path: "/prayerroom",
    name: "prayerRoom",
    component: () => import("../views/services/prayerRoom.vue"),
  },
  // {
  //     path: '/Airline_Information',
  //     name: 'AirlineInformation',
  //     component: () =>
  //         import ('../views/templates/AirlineInformationTemplate.vue')
  // },
  {
    path: "/airlinedetails",
    name: "AirlineDetails",
    component: () => import("../views/templates/AirlineDetailsTemplate.vue"),
  },

  {
    path: "/suburbantrain",
    name: "SuburbanTrain",
    component: () => import("../views/toFrom/SuburbanTrain.vue"),
  },

  {
    path: "/publicbus",
    name: "PublicBus",
    component: () => import("../views/toFrom/PublicBus.vue"),
  },

  {
    path: "/taxicabs",
    name: "TaxiCabs",
    component: () => import("../views/toFrom/TaxiCabs.vue"),
  },

  {
    path: "/carrentals",
    name: "CarRentals",
    component: () => import("../views/toFrom/CarRentals.vue"),
  },

  {
    path: "/pickupdropoff",
    name: "PickupDropoff",
    component: () => import("../views/toFrom/PickupDropoff.vue"),
  },
  {
    path: "/travelchecklist",
    name: "travelchecklist",
    component: () => import("../views/beforeyoufly/travelCheckList.vue"),
  },
  {
    path: "/beforeyouflyall",
    name: "beforeyouflyall",
    component: () => import("../views/beforeyoufly/beforeYouFlyAllList.vue"),
  },

  {
    path: "/fromntotheairport",
    name: "fromntotheairport",
    component: () => import("../views/toFrom/fromNtotheAirport.vue"),
  },

  {
    path: "/atmsservices",
    name: "atmsservices",
    component: () => import("../views/services/atmsServices.vue"),
  },
  {
    path: "/checkincounters",
    name: "checkincounters",
    component: () => import("../views/services/checkinCounters.vue"),
  },
  {
    path: "/airlineticketcounters",
    name: "airlineticketcounters",
    component: () => import("../views/services/airlineticketCounters.vue"),
  },
  {
    path: "/avsar",
    name: "avsar",
    component: () => import("../views/services/avsar.vue"),
  },
  // { //commited by vijay
  //     path: '/accessibility',
  //     name: 'accessibility',
  //     component: () =>
  //         import('../views/services/accessibility.vue')
  // },

  {
    path: "/medicalfacilities",
    name: "medicalfacilities",
    component: () => import("../views/services/medicalFacilities.vue"),
  },
  {
    path: "/about-us",
    name: "aboutus",
    component: () => import("../views/aboutUs/aboutUs.vue"),
  },
  {
    path: "/advertisement",
    name: "advertisement",
    component: () => import("../views/aboutUs/advertisement.vue"),
  },
  {
    path: "/visionandmission",
    name: "visionandmission",
    component: () => import("../views/aboutUs/visionAndmission.vue"),
  },
  {
    path: "/about-chennai-airport",
    name: "aboutchennaiairport",
    component: () => import("../views/aboutUs/AboutChennaiAirport.vue"),
  },
  {
    path: "/airlinedetails",
    name: "airlinedetails",
    component: () => import("../views/flights/AirlineDetails.vue"),
  },

  {
    path: "/multimodalconnectivity",
    name: "multimodalconnectivity",
    component: () => import("../views/toFrom/multimodalConnectivity.vue"),
  },
  {
    path: "/trackflightsdepature",
    name: "trackflightsdepature",
    component: () => import("../views/flights/trackflightsDepature.vue"),
  },
  // {
  //     path: '/trackflightsdepature',
  //     name: 'trackflightsdepature',
  //     component: () =>
  //         import ('../views/flights/trackflightsDepature.vue')
  // },
  {
    path: "/sleepinglounge",
    name: "sleepinglounge",
    component: () => import("../views/services/sleepingLounge.vue"),
  },
  {
    path: "/interterminaltransfer",
    name: "interterminaltransfer",
    component: () => import("../views/services/InterTerminalTransfer.vue"),
  },
  {
    path: "/childcare",
    name: "childcare",
    component: () => import("../views/services/childCare.vue"),
  },
  {
    path: "/moneyexchange",
    name: "moneyexchange",
    component: () => import("../views/services/moneyExchange.vue"),
  },
  {
    path: "/domestictodomestic",
    name: "domestictodomestic",
    component: () => import("../views/services/domesticTodomestic.vue"),
  },
  {
    path: "/walkalator",
    name: "walkalator",
    component: () => import("../views/services/Walkalator.vue"),
  },
  {
    path: "/entrygates",
    name: "entrygates",
    component: () => import("../views/attheairport/entryGates.vue"),
  },

  {
    path: "/exitgates",
    name: "exitgates",
    component: () => import("../views/attheairport/exitGates.vue"),
  },
  {
    path: "/pharmacies",
    name: "pharmacies",
    component: () => import("../views/services/Pharmacies.vue"),
  },

  {
    path: "/artndartefacts",
    name: "artndartefacts",
    component: () => import("../views/ArtndArtifacts/artArtifactlist.vue"),
  },

  {
    path: "/bharatnatyam",
    name: "bharatnatyam",
    component: () => import("../views/ArtndArtifacts/bharatnatyam.vue"),
  },

  {
    path: "/boatman",
    name: "boatman",
    component: () => import("../views/ArtndArtifacts/boatman.vue"),
  },

  {
    path: "/marinabeachart",
    name: "marinabeachart",
    component: () => import("../views/ArtndArtifacts/marinaBeach.vue"),
  },

  {
    path: "/templejwellery",
    name: "templejwellery",
    component: () => import("../views/ArtndArtifacts/templeJwellery.vue"),
  },

  {
    path: "/abstractpainting",
    name: "abstractpainting",
    component: () => import("../views/ArtndArtifacts/abstractPainting.vue"),
  },

  {
    path: "/templearchitecture",
    name: "templearchitecture",
    component: () => import("../views/ArtndArtifacts/templeArchitecture.vue"),
  },

  {
    path: "/landforms",
    name: "landforms",
    component: () => import("../views/ArtndArtifacts/landforms.vue"),
  },

  {
    path: "/glorosialily",
    name: "glorosialily",
    component: () => import("../views/ArtndArtifacts/glorosiaLily.vue"),
  },

  {
    path: "/lamp",
    name: "lamp",
    component: () => import("../views/ArtndArtifacts/lamp.vue"),
  },

  {
    path: "/search",
    name: "search",
    component: () => import("../views/search.vue"),
  },

  {
    path: "/faqs",
    name: "faqs",
    component: () => import("../views/faqs.vue"),
  },

  // {//added by vijay
  //     path: '/serevicetemplate',
  //     name: 'services',
  //     component: () =>
  //         import('../views/templates/services.vue')
  // },
];

let backButtonClicked = false;
window.addEventListener("popstate", () => {
  backButtonClicked = true;
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (backButtonClicked) {
    window.location.reload();
    setTimeout(() => {
      backButtonClicked = false;
    }, 2000);
  }
  next();
});

export default router;
