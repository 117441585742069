<template>
  <v-footer padless style="background-color: none !important">
    <v-card class="flex" flat tile>
      <v-card-title
        v-scrollanimation
        class="rounded-xl"
        style="
          width: 85%;
          margin: 10px auto;
          background-color: #fff;
          box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.2);
          border-radius: 24px;
        "
      >
        <v-row align="center" justify="center">
          <v-col cols="12" md="4">
            <v-card-title
              :class="
                $vuetify.breakpoint.smAndDown ? 'justify-center' : 'text-right'
              "
            >
              <v-avatar size="56">
                <v-btn class="mx-4" color="#0072FF " icon>
                  <v-img
                    size="34px"
                    :src="require('../assets/images/helpline.svg')"
                    class="my-3"
                    max-width="40"
                  />
                </v-btn>
              </v-avatar>
              <div class="subheading">
                <p class="subtitle-2 text-left">24x7 Helpline</p>
                <p class="subtitle-1">
                  <a style="color: #000" href="tel:+91 44 2256 0551"
                    >+91 44 2256 0551</a
                  >
                </p>
              </div>
            </v-card-title>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            md="4"
            :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'"
          >
            <span
              :class="{ 'follow-us': $vuetify.breakpoint.smAndDown }"
              style="font-size: 16px; font-weight: 500"
              >Follow us on</span
            >

            <v-btn
              :href="`https://www.facebook.com/AAIChennaiAirport `"
              target="_blank"
              class="mx-4 mr-0"
              color="#0072FF "
              icon
            >
              <v-img
                size="34px"
                :src="require('../assets/images/facebook.svg')"
                class="my-3"
                max-width="40"
              />
            </v-btn>

            <v-btn
              :href="`https://www.instagram.com/aaichennaiairport/ `"
              target="_blank"
              class="mx-4 mr-0"
              color="#0072FF "
              icon
            >
              <v-img
                size="34px"
                :src="require('../assets/images/instagram.svg')"
                class="my-3"
                max-width="40"
              />
            </v-btn>
            <v-btn
              :href="`https://www.youtube.com/@AAIChennaiAirport`"
              target="_blank"
              class="mx-4 mr-0"
              color="#0072FF "
              icon
            >
              <v-img
                size="34px"
                :src="require('../assets/images/youtub.svg')"
                class="my-3"
                max-width="40"
              />
            </v-btn>

            <v-btn
              :href="`https://www.kooapp.com/koo/AAIChennaiAirport`"
              target="_blank"
              class="mx-4 mr-0"
              color="#0072FF "
              icon
            >
              <v-img
                size="34px"
                :src="require('../assets/images/koo.svg')"
                class="my-3"
                max-width="40"
              />
            </v-btn>

            <v-btn
              :href="`https://twitter.com/aaichnairport`"
              target="_blank"
              class="mx-4 mr-0"
              color="#0072FF "
              icon
            >
              <v-img
                size="34px"
                :src="require('../assets/images/twitter.svg')"
                class="my-3"
                max-width="38"
              />
            </v-btn>
          </v-col>

          <v-col cols="12" md="4">
            <v-card-title
              :class="
                $vuetify.breakpoint.smAndDown ? 'justify-center' : 'text-left'
              "
            >
              <v-avatar size="56">
                <v-btn
                  class="mx-4"
                  color="#0072FF "
                  icon
                  :href="`https://twitter.com/aaichnairport`"
                  target="_blank"
                >
                  <v-img
                    size="34px"
                    :src="require('../assets/images/feedbackIcon.svg')"
                    class="my-3"
                    max-width="40"
                  />
                </v-btn>
              </v-avatar>
              <div class="subheading">
                <p class="subtitle-2 text-left">Feedback to share?</p>
                <p class="subtitle-1">
                  <router-link style="color: #000" to="/feedback"
                    >Click here <v-icon color="#000">mdi-chevron-right</v-icon>
                  </router-link>
                </p>
              </div>
            </v-card-title>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions
        class="py-3 white--text text-center mt-10 footer-action"
        style="background-color: #0b2a68; border: 5px solid #0b2a68"
      >
        <v-row align="center" justify="center">
          <v-col cols="12" md="2">
            <div>
              <a :href="`https://aai.aero`" target="_blank">
                <v-img
                  :class="{ 'footer-logo': $vuetify.breakpoint.smAndDown }"
                  :src="require('../assets/logoWhite.svg')"
                  class="my-3 ml-4"
                  max-width="160"
                />
              </a>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              :href="`https://www.aai.aero/en/privacy-policy`"
              color="white"
              text
              class="text-capitalize"
              target="_blank"
            >
              Privacy Policy
            </v-btn>
            <v-btn to="/disclaimer" color="white" text class="text-capitalize">
              Disclaimer
            </v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <div>Visits</div>
            <div class="visitor-stats">
              <div>
                <h2 class="white--text">{{ visitor?.today }}</h2>
                <div>Today</div>
              </div>
              <div>
                <h2 class="white--text">{{ visitor?.count }}</h2>
                <div>Total</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <span class="mr-4">Download App</span>
            <v-btn color="white" text class="pa-0 ma-0">
              <a
                href="https://play.google.com/store/apps/details?id=aero.aai.maa"
                target="_blank"
              >
                <v-img
                  :src="require('../assets/images/GooglePlay.png')"
                  class="my-3 mr-4"
                  max-width="100"
                />
              </a>
            </v-btn>
            <v-btn color="white" text class="pa-0">
              <a
                href=" https://apps.apple.com/in/app/chennai-international-airport/id6447828652"
                target="_blank"
              >
                <v-img
                  :src="require('../assets/images/AppStore.png')"
                  class="my-3 mr-4"
                  max-width="100"
                />
              </a>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>
<script>
import axios from "axios";

export default {
  name: "FooterContent",

  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-instagram"],
    visitor: null,
    url: process.env.VUE_APP_AIRLlINE_URL,
  }),
  methods: {
    async onLoadCounter() {
      try {
        await axios.post(`${this.url}/user/visitor/update`);
        const { data } = await axios.get(`${this.url}/user/visitor/counter`);
        if (data?.data) {
          this.visitor = data.data;
        }
      } catch (error) {}
    },
  },
  async mounted() {
    await this.onLoadCounter();
  },
};
</script>
<style type="text/css">
.subheading {
  font-size: 18px;
  font-weight: 700;
}

.subheading .subtitle-1 {
  font-size: 18px !important;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.subheading .subtitle-2 {
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}
.follow-us {
  width: 59px;
  display: inline-block;
  vertical-align: middle;
  line-height: 15px;
}

.v-btn--icon.v-size--default {
  height: 20px;
  width: 25px;
}

.visitor-stats {
  display: flex;
  justify-content: space-evenly;
}
</style>
