<template>
  <div class="home">
    <!-- Loader -->
    <div v-if="landing === null">
      <v-skeleton-loader type="card"></v-skeleton-loader>
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </div>
    <div v-if="landing !== null">
      <!-- Carousel -->
      <v-carousel
        cycle
        :show-arrows="false"
        hide-delimiter-background
        class="myslider zoom-in-out-box"
      >
        <v-carousel-item
          v-for="(banner, i) in landing.banners"
          :key="i"
          :src="`${url}${banner.web}`"
          transition="fade-transition"
        >
          <div dark class="overlay">
            <v-row
              class="fill-height"
              align="center"
              justify="center"
              style="height: 500px"
            >
              <v-col>
                <v-container
                  class="homeslider"
                  :class="{
                    responisiveSliderHome: $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <div
                    class="slider-title"
                    :class="$vuetify.breakpoint.smAndDown ? 'mt-15' : 'mt-0'"
                  >
                    {{ banner.content }}
                  </div>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>

      <v-container fluid :class="{ 'pa-0': $vuetify.breakpoint.mdAndUp }">
        <!-- Flight Search -->
        <v-container style="padding: 0 12px" class="searchBox">
          <v-card class="mx-auto mt-n12 rounded-xl">
            <v-row>
              <v-col cols="12" sm="3">
                <v-list-item two-line>
                  <v-list-item-content class="find-flight">
                    <v-list-item-title
                      class="text-h6 text-xl-h3 text-lg-h4 text-md-h5 mt-8 ml-5"
                    >
                      Find your Flight
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-divider class="mt-5 mb-5" vertical></v-divider>
              <v-col cols="12" sm="3" class="InputSection">
                <v-list-item>
                  <v-list-item-avatar rounded="0" size="18">
                    <v-img :src="require('../assets/images/search.svg')" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="">
                      <div class="mt-2">Search</div>
                      <v-text-field
                        class="mt-0 pt-3"
                        label="by City / Flight no."
                        v-model="searchFlight"
                      ></v-text-field>
                    </div>
                    <!-- <v-list-item-subtitle>by City / Flight no.</v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-divider class="mt-5 mb-5" vertical></v-divider>
              <v-col cols="12" sm="3" class="InputSection">
                <v-list-item>
                  <v-list-item-avatar rounded="0" size="20">
                    <v-img
                      :src="require('../assets/images/arrivaldepature.svg')"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div>
                      <div class="mt-2">Arrivals</div>
                      <v-select
                        class="mt-0 pt-3"
                        label="or Departures"
                        hide-details
                        two-line
                        :items="['Arrivals', 'Departures']"
                        v-model="selectFlightMode"
                      >
                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-title
                              :id="attrs['aria-labelledby']"
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                :class="{ buttonDesktop: $vuetify.breakpoint.mdAndUp }"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-btn
                      rounded
                      @click="findFlight"
                      color=""
                      dark
                      large
                      class="gradient-button common-btn mt-6 px-10"
                    >
                      Search
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-row>
            <v-col>
              <div
                class="searchTag ml-5 my-7"
                :class="{ searchTagSm: $vuetify.breakpoint.smAndDown }"
              >
                <p>Trending Searches</p>
                <span class="mt-2">BOM - MAA </span>
                <span class="mt-2">SIN - MAA </span>
                <span class="mt-2">DEl - MAA </span>
              </div>
            </v-col>
          </v-row> -->
        </v-container>

        <v-card flat class="mt-10 mb-10" v-scrollanimation>
          <!-- Header Section  -->
          <v-card-title
            class="justify-center text-uppercase page-title"
            :class="{ 'page-titleSm': $vuetify.breakpoint.smAndDown }"
          >
            {{ landing.header.title }}
          </v-card-title>
          <v-card-subtitle
            class="text-center page-sub-title"
            :class="{ 'page-subtitleSm': $vuetify.breakpoint.smAndDown }"
          >
            <h1>{{ landing.header.subTitle }}</h1>
          </v-card-subtitle>
          <!-- Services -->
          <v-tabs
            background-color=" accent-4"
            :centered="$vuetify.breakpoint.smAndDown ? false : true"
            :center-active="$vuetify.breakpoint.smAndDown ? true : false"
            class="mytabs mt-5"
            :class="{ responsiveTab: $vuetify.breakpoint.smAndDown }"
          >
            <template v-for="(service, i) in landing.services">
              <v-tab
                tag="a"
                :class="{
                  'border-radiusbefore': !i,
                  'border-radiusafter': i == landing?.services?.length - 1,
                }"
              >
                {{ service.title }}
              </v-tab>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-hover>
                      <div
                        slot-scope="{ hover }"
                        :class="`${hover ? 'imgover' : 'imgleave'}`"
                      >
                        <v-img
                          :height="400"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'rounded-xl'
                              : 'rounded-r-xl'
                          "
                          :src="`${url}${service?.images?.[0]?.web?.url}`"
                        ></v-img>
                      </div>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-card class="mx-auto" width="90%" :elevation="0">
                      <v-card-text>
                        <p class="text-h4 text--primary">{{ service.title }}</p>
                        <p class="text--primary">
                          {{ service.description }}
                        </p>
                      </v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          class="pb-0 pt-0"
                          v-for="(pc, pci) in splitArray(service)"
                          :key="pci"
                        >
                          <v-list flat class="how-can-list">
                            <v-list-item-group color="primary">
                              <v-list-item
                                v-for="(child, ci) in pc"
                                :key="ci"
                                @click="onHandleNavigation(child)"
                              >
                                <v-list-item-avatar>
                                  <v-img
                                    :src="
                                      require('../assets/images/rightArrow.svg')
                                    "
                                  />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    child.title
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="1" class="hidden-sm-and-down">
                    <v-hover>
                      <div
                        slot-scope="{ hover }"
                        :class="`${hover ? 'imgover' : 'imgleave'}`"
                      >
                        <v-img
                          :height="400"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'rounded-xl'
                              : 'rounded-l-xl'
                          "
                          :src="`${url}${
                            landing?.services?.[
                              i === landing.services.length - 1 ? 0 : i + 1
                            ]?.images?.[0]?.web?.url
                          }`"
                        ></v-img>
                      </div>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-tab-item>
            </template>
          </v-tabs>
        </v-card>
        <!-- Experience Section -->
        <v-card
          v-scrollanimation
          class="mb-10"
          :class="{ ChennaiAirportSm: $vuetify.breakpoint.smAndDown }"
        >
          <v-img
            v-bind:style="{ background: `url(${renderBanner(banner)})` }"
            class="experience-tab"
          >
            <v-card
              flat
              width="80%"
              style="margin: 0 auto; background: none"
              class=""
            >
              <v-card-text class="mt-7 vertical-tab-title">
                <div class="mt-15 text-uppercase white--text font-weight-bold">
                  {{ landing?.experience?.header?.title }}
                </div>
                <p class="text-h3 white--text">
                  {{ landing?.experience?.header?.subTitle }}
                </p>
              </v-card-text>
              <v-tabs vertical class="vertical-tab mb-15" v-model="tab">
                <v-tab
                  v-for="(experience, i) in landing?.experience?.facilities"
                  :key="i"
                  :value="experience.facility"
                  @click="onChangeTab(i)"
                >
                  <v-icon left> mdi-arrow-right-drop-circle-outline </v-icon>
                  {{ experience.facility.name }}
                </v-tab>

                <v-tab-item
                  v-for="(experience, i) in landing?.experience?.facilities"
                  :key="i"
                >
                  <v-card flat class="mx-auto" tile>
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(item, i) in experience.items"
                          :key="i"
                        >
                          <v-list-item-avatar rounded="0">
                            <v-img :src="item.icon"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.description
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-card-actions>
                      <v-btn
                        block
                        rounded
                        dark
                        class="gradient-button"
                        @click="
                          () => {
                            gtrack();
                            onHandleNavigation(experience.facility);
                          }
                        "
                      >
                        {{ experience?.button?.name }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-img>
        </v-card>
        <!-- Others Section -->
        <div v-for="(other, i) in landing.others">
          <v-row
            v-scrollanimation
            align="center"
            justify="center"
            :class="{ charmSmall: $vuetify.breakpoint.smAndDown }"
            v-if="i % 2 === 0"
          >
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="d-flex justify-center">
                    <v-hover>
                      <v-card
                        flat
                        class="mb-10 ml-2"
                        width="65%"
                        slot-scope="{ hover }"
                        :class="`${hover ? 'imgover' : 'imgleave'}`"
                      >
                        <v-img
                          class="rounded-xl"
                          :src="`${url}${other?.facility?.images?.[0]?.web?.url}`"
                        ></v-img>
                      </v-card>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5">
              <v-card flat class="mx-auto float-left">
                <v-card-subtitle class="pl-5 text-uppercase page-title">
                  {{ other?.facility?.secondary?.header }}
                </v-card-subtitle>
                <v-card-subtitle class="page-sub-title">
                  <h1>{{ other?.facility?.secondary?.title }}</h1>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text--primary pl-1 mb-5">
                    {{ other?.facility?.secondary?.description }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    rounded
                    dark
                    large
                    class="gradient-button common-btn ml-4 pl-8 pr-8"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-0' : 'mb-10'"
                    @click="onHandleNavigation(other.facility)"
                  >
                    {{ other?.button?.name }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="1"></v-col>
          </v-row>
          <v-row
            v-scrollanimation
            align="center"
            justify="center"
            :class="{ charmSmall: $vuetify.breakpoint.smAndDown }"
            v-if="i % 2 !== 0"
          >
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="5">
              <v-card flat class="mx-auto float-right">
                <v-card-subtitle class="pl-5 text-uppercase page-title">
                  {{ other?.facility?.secondary?.header }}
                </v-card-subtitle>
                <v-card-subtitle class="page-sub-title">
                  <h1>{{ other?.facility?.secondary?.title }}</h1>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text--primary pl-1">
                    <p>
                      {{ other?.facility?.secondary?.description }}
                    </p>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="onHandleNavigation(other.facility)"
                    rounded
                    dark
                    large
                    class="gradient-button common-btn ml-3 mb-5 pl-8 pr-8"
                  >
                    {{ other?.button?.name }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-hover>
                <v-card
                  flat
                  class="float-right mb-10"
                  width="80%"
                  slot-scope="{ hover }"
                  :class="`${hover ? 'imgover' : 'imgleave'}`"
                >
                  <v-img
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'rounded-xl'
                        : 'rounded-l-xl'
                    "
                    :src="`${url}${other?.facility?.images?.[0]?.web?.url}`"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
// @ is an alias to /src
import rightStickIcon from "../components/rightStickIcon";
import axios from "axios";
import EventBus from "@/event-bus";

export default {
  name: "HomeView",
  components: {
    rightStickIcon,
  },
  data() {
    return {
      loading: false,
      searchFlight: "",
      selectFlightMode: "Arrivals",
      landing: null,
      avsar: null,
      discover: null,
      tab: 0,
      banner: null,
      url: process.env.VUE_APP_AIRLlINE_URL,
    };
  },
  methods: {
    async findFlight() {
      this.$store.commit("setHomeFligthSearch", {
        searchFlight: this.searchFlight,
        selectFlightMode: this.selectFlightMode,
      });
      await this.onHandleNavigation(this.landing.search);
    },

    gtrack() {
      this.$gtag.event("latest-release-click", {
        event_category: "documentation",
        event_label: "latest release button clicked",
        value: 1,
      });
    },

    async onLoadLandingPage() {
      try {
        this.loading = true;
        const { data } = await axios.post(
          `${process.env.VUE_APP_AIRLlINE_URL}/user/landing`,
          { language: this.$store.getters.getLanguage }
        );

        if (data?.data) {
          this.landing = data.data;
          this.onChangeTab(this.tab);
          EventBus.$emit("noticeChange", this.landing.notice);
          this.loading = false;
        }
      } catch (error) {}
    },

    splitArray(data, chunk = 4) {
      let children = [...data.children];
      if (children.length) {
        if (children.length > 7) {
          return [
            children.slice(0, 4),
            [
              ...children.slice(5, 8),
              { title: "View All", name: data.name, _id: data._id },
            ],
          ];
        } else if (children.length > chunk && children.length <= 7) {
          return [
            children.slice(0, 4),
            [
              ...children.slice(5, 7),
              { title: "View All", name: data.name, _id: data._id },
            ],
          ];
        } else {
          return [
            [
              ...children.slice(0, 4),
              { title: "View All", name: data.name, _id: data._id },
            ],
          ];
        }
      } else {
        return [];
      }
    },

    async onHandleNavigation(menu) {
      this.$store.commit("setContentID", menu._id);
      if (menu.children != undefined) if (menu.children.length > 0) return;

      this.contentID = menu._id;
      // let criteria
      let facility = await axios.post(
        `${process.env.VUE_APP_AIRLlINE_URL}/user/facility/detail`,
        { _id: menu._id }
      );
      facility = { ...facility.data.data };
      // menu = { ...content.data.data, _id: menu._id };

      if (menu.title === "View All") {
        this.$router.addRoute({
          //added by vijay
          path: `/${facility.name.replace(/ /g, "_")}`,
          name: facility.name,
          component: () => import("../views/templates/maintemplate.vue"),
        });
        await this.$router.push(
          `/${facility.name.replace(/ /g, "_")}?id=${facility._id}`
        );
        EventBus.$emit("contentChange", facility);
      } else {
        if (
          facility.cms ||
          facility.cms == undefined ||
          facility.template === 2 ||
          facility.template === 3
        ) {
          this.$router.addRoute({
            //added by vijay
            path: `/${facility.name.replace(/ /g, "_")}`,
            name: facility.name,
            component: () => import("../views/templates/maintemplate.vue"),
          });
          await this.$router
            .push(`/${facility.name.replace(/ /g, "_")}`)
            .catch((err) => {
              EventBus.$emit("contentChange", facility);
              // EventBus.$emit("services", facility);
            });
          EventBus.$emit("contentChange", facility);
        } else {
          this.$router.push(facility.url).catch((err) => {
            EventBus.$emit("contentChange", facility._id);
          });
        }
      }
    },
    onChangeTab(index) {
      this.banner = this.landing.experience.facilities[index].banner.web;
      this.tab = index;
    },
    renderBanner(banner) {
      return String(banner).startsWith("http") ||
        String(banner).startsWith("data:image")
        ? banner
        : `${this.url}${banner}`;
    },
  },
  async mounted() {
    await this.onLoadLandingPage();
  },
};
</script>
<style type="text/css">
.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}
.fade-transition-enter-fron {
  opacity: 0 !important;
}
.fade-transition-enter-to {
  opacity: 1 !important;
}
.fade-transition-enter-active {
  transition: all 5s ease !important;
}
.fade-transition-leave-fron {
  opacity: 1 !important;
}
.fade-transition-leave-to {
  opacity: 0 !important;
}
.fade-transition-leave-active {
  transition: all 5s ease !important;
}

.overlay {
  width: 100%;
  height: 100%;
  /*background: linear-gradient(180deg, rgba(17, 24, 39, 0.2) 0.08%, rgba(17, 24, 39, 0.4) 41.89%, rgba(17, 24, 39, 0.8) 100%);*/
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.fill-height {
  color: #fff;
}
.slider-title {
  font-size: 40px;
  font-weight: 700;
  width: 50%;
  line-height: 60px;
}
.slider-subtitle {
  font-size: 18px;
  font-weight: 500;
}
.searchBox {
  max-width: 1050px;
  margin: 0 auto;
}
.responsiveTab .v-image.rounded-xl {
  height: 200px !important;
  width: 80% !important;
}
.how-can-list .v-avatar.v-list-item__avatar {
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

.how-can-list .v-list-item {
  border-bottom: 1px solid #e5e7eb;
  padding: 5px 0;
}
.v-slide-group__content.v-tabs-bar__content {
  margin-bottom: 12px;
}
.how-can-list .v-image__image.v-image__image--cover {
  background-size: inherit;
}
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
/* .find-flight .text-h5{font-size: 20px; font-weight: 700 !important;}
.find-flight .text-md-h6{font-size: 20px; font-weight: 700}
.find-flight .text-xl-h4{font-size: 24px; font-weight: 700}
.find-flight .text-lg-h4{font-size: 24px; font-weight: 700} */
.find-flight .text-md-h5 {
  font-size: 24px !important;
  font-weight: 700;
}

.v-list-item__avatar {
  align-self: flex-start !important;
  margin-top: 12px !important;
}
.v-list-item__content {
  padding: 1px 0 1px 0 !important;
}

.buttonDesktop.col-sm-3 .v-btn {
  max-width: 75%;
  margin-left: 20px;
}

.experience-tab {
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.foodndrink {
  background: url("../assets/images/experience-airport.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.foodndrinkImg {
  background: url("../assets/images/experience-airport.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.shoppingImg {
  background: url("../assets/images/shopping-img.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}
.dutyfreeImg {
  background: url("../assets/images/dutyfree.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}

.relaxImg {
  background: url("../assets/images/relax.jpg");
  background-size: cover;
  transition: all 5s ease;
  background-repeat: no-repeat;
}

.searchTag {
  margin: 20px 0 0 0;
}
.searchTag p {
  font-size: 18px;
  color: #374151;
  line-height: 28px;
  display: inline;
  margin-right: 20px;
  display: block;
}
.searchTag span {
  font-size: 14px;
  color: #1b63f8;
  line-height: 24px;
  margin-right: 20px;
  background: #f3f4f6;
  padding: 3px 25px 3px 14px;
  display: inline-block;
  border-radius: 15px;
  background-image: url("../assets/images/arrow.svg");
  background-position: 92% 11px;
  background-repeat: no-repeat;
}
.searchTagSm p {
  font-size: 16px !important;
}
.searchTagSm span {
  font-size: 12px !important;
}
</style>
